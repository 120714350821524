import React, {useState} from 'react'
import '../assets/stylesheets/Mail.scss';
import { openMail, selectMail, setApp } from "../redux/windowReducer";
import { useDispatch } from "react-redux";
import emailjs from 'emailjs-com';
import useSound from 'use-sound';
import swoosh from '../assets/sound_mac.mp3'


const Mail = () => {
    const dispatch = useDispatch()
    const [playSwoosh] = useSound(swoosh, { volume: 0.05 });


    const [formData, setFormData] = useState({
      from_name: '',
      email: '',
      message: ''
    });

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_CLIENT_ID)
        .then((result) => {
          playSwoosh()
          console.log(result)
        }, (error) => {
          console.log(error.text);

        });



        setTimeout(() => {

          setFormData({
            from_name: '',
            email: '',
            message: ''
          })
        }, 500);

    };

  const closeMail = () => {
    dispatch(openMail(false))
    dispatch(selectMail(false))
    document.getElementById("mail").classList.remove("clicked")
    dispatch(setApp("Agreloe"))
  }

  return (
    <div className='mailBlock'>
        <div className="mailBlock__topbar">
            <button type='button' aria-label="Close button" tabIndex="0"  className="mailBlock__topbar__close button" onClick={closeMail} onTouchStart={closeMail}><span className='sr-only'>Close</span></button>
        </div>
        <div className="mailBlock__form">
            <form onSubmit={handleSubmit}>
                <label htmlFor="from_name" className="sr-only">To:</label>
                <input type="text" value="To: agreloerika@gmail.com" disabled />
                <label htmlFor="from_name" className="sr-only">Name:</label>
                <input id="from_name" type="text" name='from_name' placeholder='Name' value={formData.from_name}  onChange={handleChange} required/>
                <label htmlFor="from_name" className="sr-only">Email:</label>
                <input id='email' type="email" name='email' placeholder='Email' value={formData.email}  onChange={handleChange} required/>
                <span></span>
                <label htmlFor="from_name" className="sr-only">Message:</label>
                <textarea id='message' name="message" placeholder='Message' value={formData.message} onChange={handleChange} required></textarea>
                <button id='button' type='submit' className="mailBlock__form__send" tabIndex={0} aria-label='Send button'>
                  <span className="sr-only">Send</span>
                </button>
            </form>
        </div>
    </div>
  )
}

export default Mail