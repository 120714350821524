import React from 'react'
import './assets/stylesheets/App.scss';
import Navbar from './components/Navbar';
import Topbar from './components/Topbar';
import Brightness from './components/Brightness';
import Notes from './components/Notes';
import Safari from './components/Safari';
import Mail from './components/Mail';
import Drawings from './components/Drawings';
import Draggable from "react-draggable";
import { selectNotes, selectDraw, selectMail, selectSafari, setApp, setIndex, openBrightness, selectRandom } from './redux/windowReducer';
import { useSelector, useDispatch } from 'react-redux';
import bgImage from './assets/img/agreloe_macOS-bg.jpg';
import bgImageWebp from './assets/img/ezgif.com-gif-maker.webp';
import Random from './components/Random';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
ReactGA.initialize("G-LPTNF901VL");
import { Helmet, HelmetProvider } from 'react-helmet-async';
import safariIcon from './assets/img/safari-icon.svg'


function App() {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);


  const window = useSelector((store)=> store.window);
  const dispatch = useDispatch();
  const nodeRef = React.useRef(null);

  const deselect = () => {
    dispatch(setApp('Agreloe'))
    dispatch(openBrightness(false))
  }

  const selectedNotes = () => {
    dispatch(setIndex(window.index + 1))
    dispatch(selectDraw(false))
    dispatch(selectSafari(false))
    dispatch(selectMail(false))
    dispatch(selectRandom(false))
    dispatch(setApp('Notes'))
    dispatch(selectNotes(true))
  }
  const selectedSafari = () => {
    dispatch(setIndex(window.index + 1))
    dispatch(selectNotes(false))
    dispatch(selectDraw(false))
    dispatch(selectMail(false))
    dispatch(selectRandom(false))
    dispatch(setApp('Safari'))
    dispatch(selectSafari(true))
  }
  const selectedMail = () => {
    dispatch(setIndex(window.index + 1))
    dispatch(selectNotes(false))
    dispatch(selectDraw(false))
    dispatch(selectSafari(false))
    dispatch(selectRandom(false))
    dispatch(setApp('Mail'))
    dispatch(selectMail(true))
  }
  const selectedDraw = () => {
    dispatch(setIndex(window.index + 1))
    dispatch(selectNotes(false))
    dispatch(selectSafari(false))
    dispatch(selectMail(false))
    dispatch(selectRandom(false))
    dispatch(setApp('Drawings'))
    dispatch(selectDraw(true))
  }
  const selectedRandom = () => {
    dispatch(setIndex(window.index + 1))
    dispatch(selectNotes(false))
    dispatch(selectSafari(false))
    dispatch(selectMail(false))
    dispatch(selectDraw(false))
    dispatch(selectRandom(true))
  }

  return (
    <HelmetProvider>
          <main id="App" className="App">
            <Helmet>
              <link rel="dns-prefetch" href="https://applesocial.s3.amazonaws.com" />
              <link rel="preload" href={safariIcon} as="image" type="image/svg+xml" />
              <title>Agreloe Mac OS</title>
            </Helmet>
          <div className="App__bg">
              <picture>
                <source srcSet={bgImageWebp} type='image/webp' />
                <img src={bgImage} alt="Purple gradient colored waves" />
              </picture>
          </div>
          <div className='App__topbar'>
            <Topbar />
          </div>
          <div className="App__screen">
            <div className="screen" onClick={deselect}>
            </div>
          {
            window.brightness.isOpen === true && (
              <div className='App__brightness'>
                <Brightness />
              </div>
            )
          }
          {
            window.safari.isOpen === true && (
              <Draggable nodeRef={nodeRef} cancel='.safariBlock__button, .safariBlock__content'>
                <div id="safari" ref={nodeRef} className='App__screen__safari' onClick={selectedSafari} onTouchStart={selectedSafari}  style={window.safari.isSelected ? {zIndex:`${window.index}`} : {zIndex: `${window.index - 1}`}}>
                <Safari />
                </div>
              </Draggable>
            )
          }
          {
            window.notes.isOpen === true && (
              <Draggable nodeRef={nodeRef} cancel='.notesBlock__button, .notesBlock__body__wrapper'>
                <div id="notes" ref={nodeRef} className='App__screen__notes' onClick={selectedNotes} onTouchStart={selectedNotes}  style={window.notes.isSelected ? {zIndex:`${window.index}`} : {zIndex: `${window.index - 1}`}}>
                <Notes />
                </div>
              </Draggable>
            )
          }
          {
            window.mail.isOpen === true && (
              <Draggable nodeRef={nodeRef} cancel='.mailBlock__topbar__close'>
                <div id="mail" ref={nodeRef} className='App__screen__mail' onClick={selectedMail} onTouchStart={selectedMail}  style={window.mail.isSelected ? {zIndex:`${window.index}`} : {zIndex: `${window.index - 1}`}}>
                <Mail />
                </div>
              </Draggable>
            )
          }
          {
            window.draw.isOpen === true && (
              <Draggable nodeRef={nodeRef} cancel='.drawBlock__button, .first, .second, .third, .fourth'>
                <div id="draw" ref={nodeRef} className='App__screen__draw' onClick={selectedDraw} onTouchStart={selectedDraw}  style={window.draw.isSelected ? {zIndex:`${window.index}`} : {zIndex: `${window.index - 1}`}}>
                <Drawings />
                </div>
              </Draggable>
            )
          }



          {
            window.random.isOpen === true && (
              <Draggable nodeRef={nodeRef} cancel='.randomBlock__topbar__close'>
                <div id="random" ref={nodeRef} className='App__screen__random' onClick={selectedRandom} onTouchStart={selectedRandom}  style={window.draw.isSelected ? {zIndex:`${window.index}`} : {zIndex: `${window.index - 1}`}}>
                <Random></Random>
            </div>
              </Draggable>
            )
          }
          </div>






          <div className='App__navbar'>
          <Navbar />
          </div>

          </main>
          </HelmetProvider>
  );
}

export default App;
