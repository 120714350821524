import React from 'react'
import '../assets/stylesheets/Pedro.scss'
import { openRandom, setApp, selectRandom } from "../redux/windowReducer";
import { useDispatch } from "react-redux";

function Random() {
  const dispatch = useDispatch();

  const closeRandom = () => {
    dispatch(openRandom(false));
    dispatch(selectRandom(false));
    document.getElementById("random").classList.remove("clicked");
    dispatch(setApp("Agreloe"));
  };

  return (
    <div className="randomBlock">
      <div className="randomBlock__topNav">
        <button
          type='button'
          aria-label="Close button"
          tabIndex="0"
          className="randomBlock__button button"
          onClick={closeRandom}
          onTouchStart={closeRandom}
        ><span className='sr-only'>Close</span></button>
        <div className="randomBlock__topNav__url"></div>
      </div>

      <div className="randomBlock__content">
        <div className='randomBlock__content__pedro css-selector'>
          <div className='randomBlock__content__pedro__circle'>
              <img className='randomBlock__content__pedro__circle__raccoon' src="pedro.webp" alt="Pedro" title="Pedro" width={160} height={160} loading="lazy" />
          </div>
        </div>

        <div className='randomBlock__content__copy'>
          <p>Developed by Erika Agrelo</p>
          <p>
            <a href="https://github.com/agreloe/agreloe-mac-os" target="_blank" rel="noopener noreferrer">Behind the scenes</a>
          </p>
          <p>© 2024 agreloe.dev</p>
        </div>
      </div>
    </div>

  )
}

export default Random