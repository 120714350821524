import React from "react";
import "../assets/stylesheets/Safari.scss";
import { openSafari, setApp, selectSafari } from "../redux/windowReducer";
import { useDispatch } from "react-redux";

const Safari = () => {
  const dispatch = useDispatch();

  const closeSafari = () => {
    dispatch(openSafari(false));
    dispatch(selectSafari(false));
    document.getElementById("safari").classList.remove("clicked");
    dispatch(setApp("Agreloe"));
  };

  return (
    <div className="safariBlock">
      <div className="safariBlock__topNav">
        <button
          className="safariBlock__button button"
          onClick={closeSafari}
          onTouchStart={closeSafari} type="button" tabIndex={0} aria-label="Close button"
        ><span className='sr-only'>Close</span></button>
        <div className="safariBlock__topNav__url"></div>
      </div>
      <div className="safariBlock__content">
        <h1>Favorites</h1>
        <div className="safariBlock__content__icons">
          <a
            href="https://www.linkedin.com/in/agreloerika/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open LinkedIn</span>
            <div className="safariBlock__content__icons__ln"></div>
          </a>
          <a
            href="https://www.behance.net/agreloe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open Behance</span>
            <div className="safariBlock__content__icons__be"></div>
          </a>
          <a
            href="https://github.com/agreloe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open GitHub</span>
            <div className="safariBlock__content__icons__gh"></div>
          </a>
        </div>
        <h3>Other sites</h3>
        <div className="safariBlock__content__projects">
          <a
            href="https://agreloe-win95.netlify.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open Agreloe Win95 website</span>
            <div className="safariBlock__content__projects__win95"></div>
          </a>
          <a
            href="https://derigoargentina.com.ar/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open DeRigo website</span>
            <div className="safariBlock__content__projects__derigo"></div>
          </a>
          <a
            href="https://matuca.es/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open Matuca website</span>
            <div className="safariBlock__content__projects__matuca"></div>
          </a>
          <a
            href="https://masajescompostela.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open Masajes website</span>
            <div className="safariBlock__content__projects__masajes"></div>
          </a>
          <a
            href="https://metro-bistro.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Open Metro Bistro website</span>
            <div className="safariBlock__content__projects__metro"></div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Safari;
