import React from "react";
import "../assets/stylesheets/Notes.scss";
import { openNotes, selectNotes, setApp } from "../redux/windowReducer";
import { useDispatch } from "react-redux";

const Notes = () => {
  const dispatch = useDispatch()

  const closeNotes = () => {
    dispatch(openNotes(false))
    dispatch(selectNotes(false))
    document.getElementById("notes").classList.remove("clicked")
    dispatch(setApp("Agreloe"))
  }

  return (
    <div className="notesBlock">
      <button type="button" aria-label="Close button" tabIndex="0" className="notesBlock__button button" onClick={closeNotes} onTouchStart={closeNotes}><span className='sr-only'>Close</span></button>
      <div className="notesBlock__name">
        <p>About</p>
      </div>
      <div className="notesBlock__body">
        <div className="notesBlock__body__wrapper">
        <h1>Erika Agrelo</h1>
                <h2>About me</h2>
                <p>Creative and detail-oriented Frontend Developer, with a background in Multimedia Design. With a keen sense of aesthetic and UI Design, and a passion for developing responsive website design. Skilled in HTLM, CSS, SASS, JavaScript and React, as well as Adobe Creative Cloud Suite.</p>

                <h2>Projects</h2>

                <h3>Freelance Frontend Developer - 2023/2024</h3>
                <p className="stack">ReactJS, NextJS, MySQL, GSAP, SASS, Tailwind, MUI, Prisma</p>
                <p>Freelance frontend developer crafting captivating websites & brand identities. Specializing in intuitive UX/UI design, responsive layouts, & branding solutions. Passionate about blending creativity with functionality to elevate brands in the digital space.</p>

                <h3>Front-End Developer - Shake Again - 2021/2023</h3>
                <p className="stack">HTML5, CSS, SASS, LESS, PHP, WordPress, Webflow, Figma, ReactJS, NextJS, Tailwind, GSAP, Photoshop, Illustrator</p>
                <p>Websites designer and developer. Working on identifying opportunities for aesthetic improvements and usability for our clients. Research and implementation of new tools and functionalities to meet and improve our customer expectations and experience. Prototyping, graphic design, creative process and identity management. Reporting to the CTO.</p>

                <h3>Freelance Multimedia Designer - 2018/2021</h3>
                <p className="stack">Photoshop, Illustrator, After Effects, Cinema4D, 3D Max</p>
                <p>Designed animated videos for clients. 3D renderings of products. Identity and brand design. Social media identity and branding design. Clo- thing printing design.</p>










                <h2>Education</h2>
                <h3>Coding BootCamp - Plataforma5</h3>
                <p>Completed a Coding Bootcamp with Plataforma5, an intensive, short-term training programs in Full-Stack web development that provided me with technical skills in JavaScript, React, Redux, React Native, MongoDB, PostgreSQL and Express, among others.</p>

                <h3>Multimedia Design - Escuela Da Vinci</h3>
                <p>Studied Multimedia Design at Primera Escuela de Arte Multimedial Da Vinci, completing more than 80% of the career program.</p>
        </div>
      </div>
    </div>
  );
};

export default Notes;
