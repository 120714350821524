import React from "react";
import "../assets/stylesheets/Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  openNotes,
  openDraw,
  openSafari,
  openMail,
  setApp,
} from "../redux/windowReducer";
import notesIcon from '../assets/img/notes-icon.svg'
import safariIcon from '../assets/img/safari-icon.svg'
import mailIcon from '../assets/img/mail-icon.svg'
import drawIcon from '../assets/img/drawings-icon.svg'

const Navbar = () => {
  const dispatch = useDispatch();
  const window = useSelector((store) => store.window);

  const safariClick = () => {
    dispatch(openSafari(true));
    dispatch(setApp("Safari"));
  };

  const notesClick = () => {
    dispatch(openNotes(true));
    dispatch(setApp("Notes"));
  };

  const mailClick = () => {
    dispatch(openMail(true));
    dispatch(setApp("Mail"));
  };

  const drawClick = () => {
    dispatch(openDraw(true));
    dispatch(setApp("Drawings"));
  };

  return (
    <div className="navbar">
      <button
        type="button"
        id="safari"
        className={`navbar__icon safari ${
          window.safari.isOpen ? "clicked" : ""
        }`}
        onClick={safariClick}
        aria-pressed={window.safari.isOpen}
        aria-expanded={window.safari.isOpen}
        aria-controls="safari-content"
        aria-label={window.safari.isOpen ? 'Close Safari window' : 'Open Safari window'}
      >
        {/* eslint-disable-next-line */}
        <img width={40} height={40} src={safariIcon} alt="Safari icon" title="Safari Icon" loading="eager" fetchpriority="high" decoding="async" />
        <span className="sr-only">{window.safari.isOpen ? 'Close Safari window' : 'Open Safari window'}</span>
      </button>
      <button
        type="button"
        id="notes"
        className={`navbar__icon notes ${window.notes.isOpen ? "clicked" : ""}
        `}
        aria-pressed={window.notes.isOpen}
        aria-expanded={window.notes.isOpen}
        aria-controls="notes-content"
        aria-label={window.notes.isOpen ? 'Close Notes window' : 'Open Notes window'}
        onClick={notesClick}
      >
        {/* eslint-disable-next-line */}
        <img width={40} height={40} src={notesIcon} alt="Notes icon" title="Notes Icon" loading="lazy"  decoding="async" fetchPriority="low" />
        <span className="sr-only">Open Notes window</span>
      </button>
      <button
        type="button"
        id="mail"
        className={`navbar__icon mail ${window.mail.isOpen ? "clicked" : ""}`}
        onClick={mailClick}
        aria-pressed={window.mail.isOpen}
        aria-expanded={window.mail.isOpen}
        aria-controls="mail-content"
        aria-label={window.mail.isOpen ? 'Close Mail window' : 'Open Mail window'}
      >
        {/* eslint-disable-next-line */}
        <img width={40} height={40} src={mailIcon} alt="Mail icon" title="Mail Icon" loading="lazy"  decoding="async" fetchPriority="low" />
        <span className="sr-only">Open Mail window</span>
      </button>
      <button
        type="button"
        id="draw"
        className={`navbar__icon drawings ${
          window.draw.isOpen ? "clicked" : ""
        }`}
        onClick={drawClick}
        aria-pressed={window.draw.isOpen}
        aria-expanded={window.draw.isOpen}
        aria-controls="draw-content"
        aria-label={window.draw.isOpen ? 'Close Drawings window' : 'Open Drawings window'}
      >
        {/* eslint-disable-next-line */}
        <img width={40} height={40} src={drawIcon} alt="Drawings icon" title="Drawings Icon" loading="lazy"  decoding="async" fetchPriority="low" />
        <span className="sr-only">Open Drawings window</span>
      </button>
    </div>
  );
};

export default Navbar;
