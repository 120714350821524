import React from "react";
import "../assets/stylesheets/Topbar.scss";
import { useSelector, useDispatch } from "react-redux";
import { openBrightness, setApp, openRandom } from "../redux/windowReducer";
import useClickOutside from "./OnClickOutside";
import useSound from "use-sound";
import funny from "../assets/sonido-gracioso.mp3";

const Topbar = () => {
  const window = useSelector((store) => store.window);
  const dispatch = useDispatch();
  const [playFunny] = useSound(funny, { volume: 0.02 });

  const dropdown = React.useRef(null);
  const bright = React.useRef(null);
  const menuItems = React.useRef([]);

  const [openApple, setOpenApple] = React.useState(false);

  const openWinBright = () => {
    if (window.brightness.isOpen) {
      dispatch(openBrightness(false));
    } else {
      dispatch(openBrightness(true));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Escape") {
      setOpenApple(false);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      const nextIndex = (index + 1) % menuItems.current.length;
      menuItems.current[nextIndex].focus();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const prevIndex =
        (index - 1 + menuItems.current.length) % menuItems.current.length;
      menuItems.current[prevIndex].focus();
    } else if (e.key === "Enter") {
      e.preventDefault();
      menuItems.current[index].click();
    }
  };

  const dateToday = () => {
    let today = new Date();
    let hours = today.getHours();
    hours = ("0" + hours).slice(-2);
    let minutes = today.getMinutes();
    minutes = ("0" + minutes).slice(-2);
    let time = `${hours}:${minutes}`;
    let date = new Date();
    let n = date.toDateString();
    let day = n.slice(0, 10);
    document.getElementById("time").value = time;
    document.getElementById("date").value = day;
  };

  React.useEffect(() => {
    dispatch(setApp("Agreloe"));
    let interval = setInterval(dateToday, 500);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useClickOutside(bright, () => {
    dispatch(openBrightness(false));
  });

  useClickOutside(dropdown, () => {
    setOpenApple(false);
  });

  const openSubmenu = (e) => {
    e.stopPropagation();
    setOpenApple(!openApple);
  };

  const openMistery = () => {
    dispatch(openRandom(true));
    setOpenApple(false);
  };

  const shutDown = () => {
    document.body.classList.add("shut-down");
    setTimeout(() => {
      playFunny();
    }, 500);
  };

  return (
    <nav className="topbar">
      <button
        id="apple"
        type="button"
        className="topbar__apple icon"
        onClick={(e) => openSubmenu(e)}
        aria-pressed={openApple}
        aria-expanded={openApple}
        aria-controls="menu-content"
        aria-haspopup="true"
        aria-label={openApple ? "Close menu" : "Open menu"}
        onKeyDown={(e) => e.key === "Escape" && setOpenApple(false)}
      >
        <span className="sr-only">
          {openApple ? "Close Menu" : "Open Menu"}
        </span>
      </button>

      <div
        id="menu-content"
        ref={dropdown}
        className={`topbar__submenu ${openApple ? "open" : ""}`}
        role="menu"
        aria-hidden={!openApple}
      >
        <ul>
          <li
            role="menuitem"
            tabIndex={openApple ? 0 : -1}
            ref={(el) => (menuItems.current[0] = el)}
            onClick={openMistery}
            onKeyDown={(e) => handleKeyDown(e, 0)}
          >
            <span>About this website</span>
          </li>
          <li role="separator" className="line"></li>
          <li
          role="menuitem"
          tabIndex={openApple ? 0 : -1}
            ref={(el) => (menuItems.current[1] = el)}
            onClick={shutDown}
            onKeyDown={(e) => handleKeyDown(e, 1)}
          >
            <span>Shut Down</span>
          </li>
        </ul>
      </div>

      <p id="tag">{window.app}</p>
      <button
        ref={bright}
        type="button"
        className="topbar__brightness icon"
        onClick={openWinBright}
        tabIndex={0}
        aria-label="Change window brightness"
        aria-pressed={window.brightness.isOpen}
        aria-expanded={window.brightness.isOpen}
        aria-controls="brightness-content"
      >
        <span className="sr-only">Change window brightness</span>
      </button>
      <label htmlFor="date" className="sr-only">Date</label>
      <input name="date" id="date" type="text" disabled />
      <label htmlFor="time" className="sr-only">Time</label>
      <input name="time" id="time" type="text" disabled />
    </nav>
  );
};

export default Topbar;
